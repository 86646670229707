<template>
  <div class="container full-height">
    <div class="row success-row">
      <div class="col-12">
        <div class="card-static card-shadow fit-content text-center">
          <span v-html="text"></span>
          <button
            class="btn-filled mt-2"
            @click="$router.push({ name: button.route })"
          >
            {{ button.text }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessPage',
  data() {
    return {
      text: '',
      button: {
        text: '',
        route: '',
      },
    };
  },
  created() {
    const type = this.$route.params.type;
    this.getText(type);
  },
  methods: {
    getText(type) {
      if (type === 'registration') {
        this.text = `<p>You have successfully registered. </p>
          <p>An email has been sent to confirm your registration.</p>`;
        this.button = {
          text: "Back to home page",
          route: 'Home',
        };
      }

      if (type === 'email-verification') {
        this.text = `<p>Your email address has been verified.</p>
          <p>You can now login.</p>`;
        this.button = {
          text: 'Login',
          route: 'Auth',
        };
      }

      if (type === 'reset-password') {
        this.text = `<p>Your password has been reset.</p>
          <p>You can now login.</p>`;
        this.button = {
          text: 'Login',
          route: 'Auth',
        };
      }
    },
  },
};
</script>

<style lang="scss">
.success-row {
  margin-top: $sp-3x;
}
</style>
